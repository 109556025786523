<template>
  <div class="">
    <b-card>
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Jumlah" label-for="v-Jumlah">
              <b-form-input type="number" v-model="form.jumlah" placeholder="Jumlah" />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="submit" variant="gradient-primary" class="mr-1 float-right"> Simpan </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  data() {
    return {
      form: {
        jumlah: null,
      },
    };
  },
};
</script>

<style></style>
