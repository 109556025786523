var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah",
      "label-for": "v-Jumlah"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Jumlah"
    },
    model: {
      value: _vm.form.jumlah,
      callback: function ($$v) {
        _vm.$set(_vm.form, "jumlah", $$v);
      },
      expression: "form.jumlah"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1 float-right",
    attrs: {
      "type": "submit",
      "variant": "gradient-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }